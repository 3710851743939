import StateStoreAdapter, { TrackingDataMutationPayload } from './types'
import { MutationTree } from 'vuex'
import { MUTATIONS } from './consts'

export default {
  [MUTATIONS.SET_TRACKING_DATA]: (state, { objective, key, data }: TrackingDataMutationPayload) => {
    const objectiveTracking = state.tracking[objective] || (state.tracking[objective] = {})
    objectiveTracking[key] = data
  },
  [MUTATIONS.SET_COMPLETED]: (state, id) => {
    const objective = state.objectives[id]
    if (!objective) return
    objective.completed = true
  }
} as MutationTree<StateStoreAdapter>
