import { GetterTree } from 'vuex'
import StateStoreAdapter from './types'

export default {
  completionStatus: state => {
    const numberOfObjectives = Object.values(state.objectives)
      .filter(o => !o.ignored).length

    if (numberOfObjectives === 0) return 100

    const completedObjectives = Object.values(state.objectives)
      .filter(o => !o.ignored && o.completed).length

    return completedObjectives / numberOfObjectives * 100
  },
  isObjectiveCompleted: state =>
    (objective: string | number) => !!state.objectives[objective]?.completed
} as GetterTree<StateStoreAdapter, any>
